import React from 'react';
import howwork1 from "../../assets/images/howwork1.jpg"
import howwork2 from "../../assets/images/howwork2.jpg"
import { StaticQuery, graphql } from "gatsby"

const Mission = () => (
   <StaticQuery
    query={graphql`
       query {
         allWpPage(filter: {slug: {eq: "career"}}) {
            edges {
              node {
                slug
                TeamlanceCareer {
                  learningOnAndOffline {
                    title
                    image1 {
                     sourceUrl
                    }
                    image2 {
                     sourceUrl
                    }
                    headline
                    workSteps {
                      title
                      description
                      iconSvg
                    }
                  }
                }
              }
            }
          }
       }
    `}
    render={data => {
       return(
         data && data.allWpPage &&
         data.allWpPage.edges &&
         data.allWpPage.edges[0] &&
         data.allWpPage.edges[0].node &&
         data.allWpPage.edges[0].node.TeamlanceCareer &&
         data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline &&
         <section className="mission-sec about-sec ">
         <div className="workflow-triangle">
            <svg width="366" height="185" viewBox="0 0 366 185" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M51.8221 167.583L-35.3348 87.7328L-40 34.2603L358.287 64.1671L83.1531 173.347C72.4676 177.588 60.2986 175.349 51.8221 167.583Z" fill="#D8F4FF"></path>
            </svg>
         </div>
         <div className="container pb-0">
            <div className="row mt-5 d-flex justify-content-between carrer-worksec">
               <div className="col-md-5">
                  <div style={{position:"relative", zIndex:9}} className="row about-heading d-flex justify-content-between pb-4 ">
                     <div className="col-12">
                        <p className="text-uppercase">{data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline.title}</p>
                        <h1 className="mb-3">{data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline.headline}</h1>
                     </div>
                  </div>
                  {data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline.workSteps
                    && data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline.workSteps.length>0
                    && data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline.workSteps.map((worksteps,key)=>{
                         return(
                           <div key={key} className="solution-content d-flex flex-row flex-wrap m-hide" >
                              <div dangerouslySetInnerHTML={{ __html: worksteps.iconSvg }}  className="icon-s bg-green"></div>
                              <div className="s-cnt">
                                 <h4 className="m-0">{worksteps.title}</h4>
                                 <p>{worksteps.description}</p>
                              </div>
                            </div>
                         )
                    })}
                 
                  <div id="myaddCarousel" className="carousel slide textcarousel " data-ride="carousel">
                     <ol className="carousel-indicators d-flex">
                        {data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline.workSteps
                        && data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline.workSteps.length>0
                        && data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline.workSteps.map((worksteps,key)=>{
                        return(
                           <li key={key} data-target="#carouselExampleIndicators" data-slide-to={key} className={key===0?"active":""}></li>)
                        })
                       }
                     </ol>
                     <div className="carousel-inner">
                        {data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline.workSteps
                           && data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline.workSteps.length>0
                           && data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline.workSteps.map((worksteps,key)=>{
                              return(
                                 <div key={key} className={`item carousel-item ${key===0?'active':""}`}>
                                    <div className="solution-content d-flex flex-row flex-wrap" >
                                       <div dangerouslySetInnerHTML={{ __html: worksteps.iconSvg }} className="icon-s bg-green"></div>
                                       <div className="s-cnt">
                                          <h4 className="m-0">{worksteps.title}</h4>
                                          <p>{worksteps.description}</p>
                                       </div>
                                    </div>
                                 </div>
                              )
                           })
                        }
                     </div>
                  </div>
               </div>
               <div className="col-md-7">
                  <div className="img-box ">
                     <img src={data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline.image1.sourceUrl} alt="img" />
                  </div>
                  <div className="img-box m-0">
                     <img src={data.allWpPage.edges[0].node.TeamlanceCareer.learningOnAndOffline.image2.sourceUrl} alt="img" />
                  </div>
               </div>
            </div>
         </div>
       </section>
       )
    }}
   />
  
)

export default Mission;