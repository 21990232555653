import React from 'react';
import { StaticQuery, graphql } from "gatsby"

const LatestJob = () => (
   <StaticQuery
    query={graphql`
       query {
         allWpPage(filter: {slug: {eq: "career"}}) {
            edges {
              node {
                slug
                TeamlanceCareer {
                  latestJobs {
                    title
                    jobs {
                      category
                      iconSvg
                      jobDetailsPage
                      jobLocation
                      jobType
                      subTitle
                      title
                    }
                  }
                }
              }
            }
          }
       }
    `}
    render={data => {
       return(
         data && data.allWpPage &&
         data.allWpPage.edges &&
         data.allWpPage.edges[0] &&
         data.allWpPage.edges[0].node &&
         data.allWpPage.edges[0].node.TeamlanceCareer &&
         data.allWpPage.edges[0].node.TeamlanceCareer.latestJobs &&
        <section className="table-sec">
         <div className="container">
            <div className="col-12 ltstjb-table">
               <table className="table ">
                  <thead>
                     <tr>
                        <th colspan="2">
                           <h3>{data.allWpPage.edges[0].node.TeamlanceCareer.latestJobs.title}</h3>
                        </th>
                        <th width="86px" className="text-right blue-color">Full Time</th>
                        <th width="160px" align="right" className="text-right">Part Time</th>
                     </tr>
                  </thead>
                  <tbody>
                     {data.allWpPage.edges[0].node.TeamlanceCareer.latestJobs.jobs
                     &&data.allWpPage.edges[0].node.TeamlanceCareer.latestJobs.jobs.length>0
                     &&data.allWpPage.edges[0].node.TeamlanceCareer.latestJobs.jobs.map((job,key)=>{
                        return(
                           <tr key={key}>
                              <td>
                                 <div className="d-flex">
                                    <div dangerouslySetInnerHTML={{ __html: job.iconSvg }} className="tbl-icon green-bg"></div>
                                    <div className="tbl-titile ml-4">
                                       <h2 className="m-0">{job.title}</h2>
                                       <p className="m-0 p-0 lcolor">{job.subTitle}</p>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                 {job.category}
                              </td>
                              <td className="text-right">
                                 {job.jobType}
                              </td>
                              <td className="text-right">
                                 {job.jobLocation}
                              </td>
                           </tr>
                        )
                     })}
                  </tbody>
               </table>
            </div>
         </div>
         <div className="why-triangle">
            <svg width="489" height="212" viewBox="0 0 489 212" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M490 67.5004L366.13 17.6306L7.49989 116.5L490 141.5L490 67.5004Z" fill="#F4F8FF"></path>
            </svg>
         </div>
        </section>
       )
    }}
   />
  
)

export default LatestJob;