import React from 'react';
import { StaticQuery, graphql } from "gatsby"

const WFH = () => (
    <StaticQuery
    query={graphql`
       query {
        allWpPage(filter: {slug: {eq: "career"}}) {
            edges {
              node {
                slug
                TeamlanceCareer {
                  easeOfWork {
                    title
                    headline
                    description
                    boldText
                  }
                  benefitsThatBenefitYou {
                    title
                    description
                    headline
                  }
                }
              }
            }
          }
       }
    `}
    render={data => {
        return(
            data && 
            data.allWpPage &&
            data.allWpPage.edges &&
            data.allWpPage.edges[0] &&
            data.allWpPage.edges[0].node &&
            data.allWpPage.edges[0].node.TeamlanceCareer &&
            <section id="whyus" className="sectionwhy-p pb-0"> 
                <div className="container">
                    <div className="row  d-flex ">
                        <div className="col-md-7 about-heading mt-0">
                            <p className="text-uppercase">{data.allWpPage.edges[0].node.TeamlanceCareer.easeOfWork.title}</p>
                            <h1 className="mb-3">{data.allWpPage.edges[0].node.TeamlanceCareer.easeOfWork.headline}</h1>
                            <p className="col-md-10 why-disp mb-3">
                                {data.allWpPage.edges[0].node.TeamlanceCareer.easeOfWork.description}
                            </p>
                            <p className="tm-c">{data.allWpPage.edges[0].node.TeamlanceCareer.easeOfWork.boldText}</p>
                        </div>
                        <div className="col-md-5 about-heading mt-0">
                            <p className="text-uppercase">{
                                data.allWpPage.edges[0].node.TeamlanceCareer.benefitsThatBenefitYou.title
                            }</p>
                            <h1 className="mb-3">{data.allWpPage.edges[0].node.TeamlanceCareer.benefitsThatBenefitYou.headline}</h1>
                            <span className="list-unstyled ul-list" dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.TeamlanceCareer.benefitsThatBenefitYou.description}}></span>
                           
                        </div>
                    </div>
                </div>
            </section>
          )
      }}
    />
    
)

export default WFH