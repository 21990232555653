import React from 'react';
import value from "../../assets/images/value.png"
import { StaticQuery, graphql } from "gatsby"

const OurValue = () => (
   <StaticQuery
    query={graphql`
       query {
         allWpPage(filter: {slug: {eq: "career"}}) {
            edges {
              node {
                slug
                TeamlanceCareer {
                  browseJobsByCategory {
                    title
                    description
                    jobCategories {
                      fieldGroupName
                      iconSvg
                      subTitle
                      title
                    }
                  }
                }
              }
            }
          }
       }
    `}
    render={data => {
       return(
         data && data.allWpPage &&
         data.allWpPage.edges &&
         data.allWpPage.edges[0] &&
         data.allWpPage.edges[0].node &&
         data.allWpPage.edges[0].node.TeamlanceCareer &&
         data.allWpPage.edges[0].node.TeamlanceCareer.browseJobsByCategory &&
         <section className="our-value value-padding jobs">
         <div className="value-img mobile-hidden">
            <img alt="value" src={value}/>
         </div>
         <div className="container">
            <div className="row  d-flex align-items-center ">
               <div className="col-md-12 about-heading  text-center mt-0">
                  <h1 className="mb-3">{data.allWpPage.edges[0].node.TeamlanceCareer.browseJobsByCategory.title}</h1>
                  <p className="why-disp mb-5">
                  {data.allWpPage.edges[0].node.TeamlanceCareer.browseJobsByCategory.description}
                  </p>
               </div>
               <div className="col-md-9 mx-auto">
                  <div className="row">
                     {data.allWpPage.edges[0].node.TeamlanceCareer.browseJobsByCategory.jobCategories
                      &&data.allWpPage.edges[0].node.TeamlanceCareer.browseJobsByCategory.jobCategories.length>0
                      &&data.allWpPage.edges[0].node.TeamlanceCareer.browseJobsByCategory.jobCategories.map((jobs,key)=>{
                         return(
                           <div key={key} className="col-4 our-jobs col-sm-4 text-center border-0">
                                 <div dangerouslySetInnerHTML={{ __html: jobs.iconSvg }} className="flow-icon  f-4"></div>
                                 <h6>{jobs.title}</h6>
                                 <p>{jobs.subTitle}</p>
                           </div>
                         )
                      })

                     }
                  </div>
               </div>
            </div>
          </div>
        </section>
       )
    }}
   />
  
)

export default OurValue;