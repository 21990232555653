import React from 'react';
import singlecbg from "../../assets/images/single-c-bg.png"
import { StaticQuery, graphql } from "gatsby"

const Banner = () => (
  <StaticQuery
    query={graphql`
       query {
         allWpPage(filter: {slug: {eq: "career"}}) {
            edges {
              node {
                slug
                TeamlanceCareer {
                  header {
                    title
                    description
                  }
                }
              }
            }
          }
       }
    `}
    render={data => {
        return(
            data 
            && data.allWpPage 
            && data.allWpPage.edges &&
            data.allWpPage.edges[0] &&
            data.allWpPage.edges[0].node &&
            data.allWpPage.edges[0].node.TeamlanceCareer &&
            <section className="workflow-banner">
                <div className="bn-bg quote-bg">
                    <img src={singlecbg} alt="case study" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 d-flex flex-column sectiontop-pd">
                            <div className=" quote-banner workflow text-center mission-banner case-heading my-auto ml-0 ">
                                <h3 className="">{data.allWpPage.edges[0].node.TeamlanceCareer.header.title}</h3>
                                <p>{data.allWpPage.edges[0].node.TeamlanceCareer.header.description}</p>
                            </div>
                        </div>
                    </div> 
                </div>
             </section>
        )
    }}
   />
    
)

export default Banner;