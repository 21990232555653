import React from 'react';
import solutionabout from "../../assets/images/solution-about.png";
//import ans from "../../assets/images/ans.png";
import { StaticQuery, graphql } from "gatsby"

const BuildBetter = () => (
   <StaticQuery
    query={graphql`
       query {
         allWpPage(filter: {slug: {eq: "career"}}) {
            edges {
              node {
                slug
                TeamlanceCareer {
                  havingPurpose {
                    title
                    headline
                    description
                    clientSaying
                    clientImage {
                      sourceUrl
                    }
                    clientDetails
                  }
                }
              }
            }
          }
       }
    `}
    render={data => {
       return(
         data && 
         data.allWpPage &&
         data.allWpPage.edges &&
         data.allWpPage.edges[0] &&
         data.allWpPage.edges[0].node &&
         data.allWpPage.edges[0].node.TeamlanceCareer &&
         data.allWpPage.edges[0].node.TeamlanceCareer.havingPurpose &&
         <section id="solutions" className="solution-padding heaving-purpose">
            <div className="tm-bg-sol mobile-hidden">
               <img alt="solutionabout" src={solutionabout} /> 
            </div>
            <div className="container">
               <div className="row mt-5 d-flex justify-content-between">
                  <div className="col-md-7 p-0">
                     <div style={{position:"relative", zIndex:9}} class="col-md-10 about-heading">
                        <p className="text-uppercase">{data.allWpPage.edges[0].node.TeamlanceCareer.havingPurpose.title}</p>
                        <h1>{data.allWpPage.edges[0].node.TeamlanceCareer.havingPurpose.headline}</h1>
                        <p className="why-disp mb-5 p-color">
                        {data.allWpPage.edges[0].node.TeamlanceCareer.havingPurpose.description}
                        </p>
                     </div>
                  </div>
                  <div className="col-md-5">
                     <div className=" solution-right p-0 bg-white">
                        <figure className="mb-0">
                           <img alt="ans" src={data.allWpPage.edges[0].node.TeamlanceCareer.havingPurpose.clientImage.sourceUrl}/>
                           <div className="figsvg">
                              <svg width="21" height="16" fill="none">
                                 <path d="M16.13 15.89a3.93 3.93 0 01-3.02-1.23c-.4-.43-.7-.95-.94-1.56a6.15 6.15 0 01-.35-2.16c0-3.07 1.93-6.6 5.8-10.61l2.58 2.49a17.6 17.6 0 00-2.31 2.64 11.1 11.1 0 00-1.35 2.63 3.88 3.88 0 011.26 7.47c-.51.22-1.07.33-1.67.33zm-11.02 0a3.93 3.93 0 01-3.02-1.23c-.39-.43-.7-.95-.93-1.56a6.15 6.15 0 01-.36-2.16C.8 7.87 2.74 4.34 6.6.33l2.58 2.49c-.4.4-.82.81-1.23 1.26-.39.45-.75.9-1.08 1.38a11.12 11.12 0 00-1.35 2.63 3.88 3.88 0 011.26 7.47c-.5.22-1.06.33-1.67.33z" fill="#19B8F8"/>
                              </svg>
                           </div>
                        </figure>
                        <div className="sec-padding">
                           <p>{data.allWpPage.edges[0].node.TeamlanceCareer.havingPurpose.clientSaying}</p>
                           <p className="light-text font-normal">{data.allWpPage.edges[0].node.TeamlanceCareer.havingPurpose.clientDetails}</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
       )
    }}
   />
  
)

export default BuildBetter;