import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/career/banner";
import Mission from "../components/career/mission";
import BuildBetter from "../components/career/buildbetter";
import WFH from "../components/career/wfh";
import OurValue from "../components/career/ourvalue";
import LatestJob from "../components/career/latestjob";
import Testimonial from "../components/home/testimonial";

const Career = () => (
    <Layout>
        <SEO title="Career" />
        <div class="col-12 wrapper main-bggrey p-0">
             <div class="col-12 inner-page-content home-inner px-0">
                  <Banner/>
             </div>
        </div>
        <Mission/>
        <BuildBetter/>
        <WFH/>
        <OurValue/>
        <LatestJob/>
        <Testimonial/>
    </Layout>
)

export default Career